<template>
  <v-dialog v-model="dialog" class="dialog" max-width="600" persistent>
    <v-card class="card">
      <h2 class="text-center font-weight-regular">
          {{$t("sure_delete")}}
      </h2>
      <v-row>
        <v-col class="mt-7 mb-4 text-center" cols="12">
          <v-btn
            class="mr-5 font-weight-bold"
            style="box-shadow: none"
            @click="$emit('functionAgree', dialogItem.id)"
            height="42"
            :loading="isdelete"
            >{{ $t("yes") }}
          </v-btn>
          <v-btn
            class="button font-weight-bold"
            height="42"
            @click="$emit('functionCancel')"
            >{{ $t('no') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "Popup",
  props: {
    dialog: Boolean,
    isdelete: Boolean,
    dialogItem: Object,
  },
};
</script>
